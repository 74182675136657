<template>
    <p class="password-rules">
        {{ $t('passwordRules.headline') }}
        <ul>
            <li>
                {{ $t('passwordRules.eightCharacters') }}
                <v-icon v-if="hasLength" class="check-icon">check</v-icon>
            </li>
            <li>
                {{ $t('passwordRules.oneNumber') }}
                <v-icon v-if="hasNumber" class="check-icon">check</v-icon>
            </li>
            <li>
                {{ $t('passwordRules.oneCapital') }}
                <v-icon v-if="hasCap" class="check-icon">check</v-icon>
            </li>
        </ul>
    </p>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        password: {
            type: String,
            default: '',
        }
    },

    computed: {
        hasNumber(): boolean {
            return !!this.password.match(/[0-9]/);
        },

        hasLength(): boolean {
            return this.password.length > 7;
        },

        hasCap(): boolean {
            return !!this.password.match(/[A-Z]/);
        },
    },
});
</script>

<style lang="postcss" scoped>
.password-rules {
    color: var(--color-dark-tint);
    text-align: start;
}

.check-icon {
    margin: -0.3em 0;
}
</style>
