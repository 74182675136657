<template>
    <section class="text-center" data-test-id="Apps registration step">
        <h1 class="t-title mb-8">{{ $t('account.appDownloadRegistrationStep.headline') }}</h1>

        <p class="mx-12">{{ $t('account.appDownloadRegistrationStep.body') }}</p>

        <app-download-links stacked />

        <p>
            <router-link :to="nextRoute" class="continue-link">{{ $t('account.appDownloadRegistrationStep.continue') }}</router-link>
        </p>
    </section>
</template>

<script lang="ts">
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        AppDownloadLinks,
    },

    props: {
        nextRoute: { type: String, default: '' },
    }
});
</script>

<style lang="postcss" scoped>
.continue-link {
    display: inline-block;
    font-weight: 500;
    margin-top: 2em;
    text-decoration: none;
}
</style>
