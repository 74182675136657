<template>
    <div class="tracker-callout">
        <div>{{ $t('heading') }}</div>
        <div>{{ $t('body') }}</div>

        <div class="buttons">
            <base-button
                to="/tracker/heat/impact"
                outlined
                color="var(--color-red-fanta)"
            >
                {{ $t('ctaHeat') }}
            </base-button>

            <base-button
                to="/tracker/flooding/floodDepth"
                outlined
                color="var(--color-blue-ocean)"
            >
                {{ $t('ctaFlooding') }}
            </base-button>
        </div>

        <v-divider class="mt-4" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    i18n: {
        messages: {
            en: {
                heading: 'Short on time?',
                body: 'Use the buttons below to create a quick report without signing-up.',
                ctaHeat: 'Extreme heat',
                ctaFlooding: 'Flooding',
            },

            es: {
                heading: '¿Corto tiempo?',
                body: 'Use los botones a continuación para crear un informe rápido sin registrarse.',
                ctaHeat: 'Calor extremo',
                ctaFlooding: 'Inundación',
            },
        },
    },
});
</script>

<style scoped>
.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-block-start: 12px;
}

.buttons > * {
    flex-grow: 1;
}
</style>
