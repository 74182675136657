<template>
    <heading-level>
        <heading :class="{ 'd-sr-only': $vuetify.breakpoint.xs }">{{ $t('nav.sensorsAndIntegrations') }}</heading>

        <v-layout v-for="d in userDevices" :key="d.id" class="device mb-8">
            <v-flex shrink>
                <async-image v-if="d.images.length" :image="d.images[0]" :height="75" :width="75" :alt="`${d.type.label} location photo`" />
            </v-flex>
            <v-flex class="pl-3">
                <p class="mb-0"><strong>{{ d.type.label }}</strong></p>
                <p>{{ d.addressComponents.city }} {{ d.addressComponents.state }} {{ d.addressComponents.countryCode }}</p>
            </v-flex>
            <v-flex shrink>
                <base-button text color="primary">Remove</base-button>
            </v-flex>
        </v-layout>

        <heading-level>
            <heading>{{ $t('settings.sensors.wantToHost.headline') }}</heading>
            <i18n tag="p" path="settings.sensors.wantToHost.content">
                <a href="mailto:team@iseechange.org">team@iseechange.org</a>
            </i18n>
        </heading-level>

        <br>

        <heading-level>
            <heading>SciStarter</heading>
            <p>
                SciStarter connects people to citizen science projects, citizen
                scientists, and resources. Learn more at
                <a href="https://scistarter.org">scistarter.org</a>.
            </p>
            <p>
                If you are a registered user of SciStarter and the email address
                you are using in your ISeeChange profile matches that of your
                SciStarter account, posting observations on ISeeChange will
                automatically contribute to your record as a SciStarter citizen
                scientist.
            </p>
            <p>
                No location data or other personally identifiable information is ever shared with SciStarter.
            </p>
        </heading-level>
    </heading-level>
</template>
<script lang="ts">
import AsyncImage from '@/components/AsyncImage.vue';
import { CurrentUser, Device, User } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'ProfileSensors',
    components: {
        AsyncImage,
        Heading,
        HeadingLevel,
    },
    data() {
        return {
        };
    },
    computed: {
        currentUser(): CurrentUser | User | null {
            return this.$store.state.account.currentUser;
        },
        userDevices(): Device[] {
            return this.$store.state.account.userDevices;
        },
    },
    created() {
        this.$store.dispatch('fetchDevices');
    },

});
</script>
<style lang="postcss" scoped>
.device :deep(.image-wrapper) {
    width: 75px;
}
</style>
