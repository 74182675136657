<template>
    <heading-level>
        <form @submit="handleSubmit">
            <heading :class="$vuetify.breakpoint.xs ? 'd-sr-only' : ''">{{ $t('title') }}</heading>

            <heading-level tag="section">
                <heading>{{ $t('publicDetailsHeading') }}</heading>

                <p class="label">{{ $t('publicDetailsDescription') }}</p>

                <div class="field">
                    <base-photo-input @change="state.avatar = $event">
                        <span class="avatar-wrapper">
                            <universal-image :src="state.avatar ?? '#'" alt="" class="avatar" />
                            <span class="avatar-icon" role="presentation">+</span>
                        </span>
                        {{ $t('profilePicture') }}
                    </base-photo-input>
                </div>

                <loading-indicator v-if="clientGroupsLoading" />

                <div v-if="clientGroupsWithLogos.length !== 0" class="field">
                    <button
                        v-for="clientGroup of clientGroupsWithLogos"
                        :key="clientGroup.id"
                        type="button"
                        class="client-group-logo-button"
                        @click="state.avatar = clientGroup.logo"
                    >
                        <universal-image :src="clientGroup.logo" alt="" class="avatar" />

                        <span>
                            <span class="label">{{ $t('clientGroup') }}</span>
                            <br>
                            {{ clientGroup.name }}
                        </span>

                        <span class="action">
                            <span :class="{ 'd-sr-only': $vuetify.breakpoint.xs }">{{ $t('useClientGroupLogo') }}</span>
                            <v-icon style="color: inherit;">arrow_upward</v-icon>
                        </span>
                    </button>
                </div>

                <base-input v-model="state.firstName" :label="$t('firstName')" autocomplete="given-name" />

                <base-input v-model="state.lastName" :label="$t('lastName')" autocomplete="family-name" />

                <base-input v-model="state.description" :label="isClientUser ? $t('companyAndRoleForClient') : $t('companyAndRoleForUser')" />
            </heading-level>

            <template v-if="currentUser?.clientGroups?.[0]">
                <div tag="section" class="byline-preview">
                    <span class="label">{{ $t('bylineLabel') }}</span>

                    <div class="byline">
                        <universal-image :src="state.avatar ?? '#'" alt="" class="avatar" />{{ ' ' }}
                        <span class="name">{{ state.firstName }} {{ state.lastName }}</span>
                        &ndash;
                        {{ currentUser.clientGroups[0].name }}
                    </div>
                </div>
            </template>

            <heading-level tag="section" class="private">
                <heading>{{ $t('privateDetailsHeading') }}</heading>

                <p class="label">{{ $t('privateDetailsDescription') }}</p>

                <base-input v-model="state.email" type="email" :label="$t('email')" autocomplete="email" />

                <base-input v-model="state.mobilePhone" :label="$t('phone')" autocomplete="tel" inputmode="tel" hide-details @input="handlePhoneNumberInput" />

                <transition-expand :value="state.mobilePhone.trim().length > 0" style="margin-block-start: -1em">
                    <v-checkbox v-model="state.smsPermitted" :label="$t('account.phonePermitSms')" />
                </transition-expand>

                <two-step-address-selector
                    :location-label="$t('location')"
                    :location-hint="$t('locationHint')"
                    :coordinates-label="false"
                    :location.sync="state.location"
                    :coordinates.sync="state.lngLat"
                />

                <p class="label">{{ $t('locationPrivacy') }}</p>

                <base-fieldset>
                    <template #legend>{{ $t('unitsOfMeasurement') }}</template>

                    <v-radio-group v-model="state.measurementSystem">
                        <layout-row>
                            <layout-column>
                                <v-radio value="IMPERIAL" :label="$t('imperial')" />
                            </layout-column>

                            <layout-column>
                                <v-radio value="METRIC" :label="$t('metric')" />
                            </layout-column>
                        </layout-row>
                    </v-radio-group>
                </base-fieldset>
            </heading-level>

            <div class="footer">
                <base-button
                    type="submit"
                    x-large
                    color="primary"
                    block
                    :disabled="!hasUnsavedChanges"
                    :loading="submissionInProgress"
                >{{ $t('submit') }}</base-button>
            </div>
        </form>

        <div v-if="$vuetify.breakpoint.xs" class="delete-container">
            <base-button :to="{ name: 'account.delete' }" text>
                <v-icon left>warning</v-icon>
                {{ $t('nav.deleteAccount') }}
            </base-button>
        </div>
    </heading-level>
</template>

<script lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';
import BasePhotoInput from '@/components/BasePhotoInput.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import UniversalImage from '@/components/UniversalImage.vue';
import { trackProfileUpdate } from '@/tracking';
import { ClientGroup, CurrentUser, MBAddressObj, Photo } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import { parseAddressComponentsFromMBobj } from '@/util.mapbox';
import Vue from '@/vueTyped';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                title: 'Your profile',
                publicDetailsHeading: 'Public details',
                publicDetailsDescription: 'Visible when you post, comment and on your profile.',
                profilePicture: 'Your profile picture',
                firstName: 'First name',
                lastName: 'Last name',
                companyAndRoleForClient: 'Role (Optional)',
                companyAndRoleForUser: 'Organization (Optional)',
                clientGroup: 'Customer group',
                useClientGroupLogo: 'Use this logo',
                bylineLabel: 'Your post comments will appear as:',
                privateDetailsHeading: 'Login and communication details',
                privateDetailsDescription: 'These details will only be used to send you notifications, updates on your posts, and occasional product updates.',
                email: 'Email',
                phone: 'Phone (Optional)',
                location: 'Home address',
                locationHint: 'Providing your location ensures you get updates and alerts about storms, flooding trends and local responses. The more exact, the more relevant the alerts will be!',
                locationPrivacy: 'Your profile location will never be shared publicly or displayed to other users.',
                unitsOfMeasurement: 'Units of measurement',
                imperial: 'Imperial',
                metric: 'Metric',
                submit: 'Save',
            },

            es: {
                title: 'Tu perfil',
                publicDetailsHeading: 'Detalles públicos',
                publicDetailsDescription: 'Visible cuando publique, comente y sobre su perfil.',
                profilePicture: 'Tu foto de perfil',
                firstName: 'Nombre de pila',
                lastName: 'Apellido',
                companyAndRoleForClient: 'Rol (opcional)',
                companyAndRoleForUser: 'Organización (opcional)',
                clientGroup: 'Grupo de clientes',
                useClientGroupLogo: 'Use este logotipo',
                bylineLabel: 'Los comentarios de su publicación aparecerán como:',
                privateDetailsHeading: 'Detalles de inicio de sesión y comunicación',
                privateDetailsDescription: 'Estos detalles solo se utilizarán para enviarle notificaciones, actualizaciones de sus publicaciones y actualizaciones ocasionales de productos.',
                email: 'Correo electrónico',
                phone: 'Teléfono (opcional)',
                location: 'Dirección de la casa',
                locationHint: 'Proporcionar su ubicación garantiza obtener actualizaciones y alertas sobre tormentas, tendencias de inundación y respuestas locales. ¡Cuanto más exactos, más relevantes serán las alertas!',
                locationPrivacy: 'La ubicación de su perfil nunca se compartirá públicamente o se mostrará a otros usuarios.',
                unitsOfMeasurement: 'Unidades de medición',
                imperial: 'Imperial',
                metric: 'Métrica',
                submit: 'Ahorrar',
            },
        },
    },

    components: {
        BaseButton,
        BaseInput,
        BasePhotoInput,
        Heading,
        HeadingLevel,
        LayoutColumn,
        LayoutRow,
        LoadingIndicator,
        TransitionExpand,
        TwoStepAddressSelector,
        UniversalImage,
    },

    data() {
        return {
            state: {
                avatar: null as Photo | File | null,
                firstName: '',
                lastName: '',
                mobilePhone: '',
                smsPermitted: false,
                description: '',
                email: '',
                location: null as MBAddressObj | null,
                lngLat: null as [number, number] | null,
                measurementSystem: undefined as NonNullable<CurrentUser['userSettings']>['preferredSystemOfMeasure'],
            },
            clientGroupsLoading: false,
            clientGroupsWithLogos: [] as ClientGroup[],
            submissionInProgress: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        isClientUser(): boolean {
            return (this.currentUser?.clientGroups?.length ?? 0) > 0;
        },

        hasUnsavedChanges(): boolean {
            return (
                this.state.avatar !== this.currentUser?.avatar ||
                this.state.firstName !== this.currentUser?.firstName ||
                this.state.lastName !== this.currentUser?.lastName ||
                this.state.mobilePhone !== this.currentUser?.mobilePhone ||
                this.state.smsPermitted !== this.currentUser?.communicationPreferences.notifyMethod.includes('sms') ||
                this.state.description !== this.currentUser?.description ||
                this.state.email !== this.currentUser?.email ||
                this.state.lngLat?.join() !== [this.currentUser?.lng, this.currentUser?.lat].join() ||
                this.state.measurementSystem !== this.currentUser?.userSettings?.preferredSystemOfMeasure
            );
        },

        // locationPreview() {
        //     return this.state.location...
        // },
    },

    watch: {
        currentUser: {
            immediate: true,
            handler() {
                this.loadClientGroups();
                this.resetState();
            },
        },
    },

    mounted() {
        // TODO: Handle router navigation
        addEventListener('beforeunload', this.handleUnload);

        // TODO: Why do we have to do this again for the location to be set?
        // Check on the Two-step address picker.
        this.resetState();
    },

    beforeDestroy() {
        removeEventListener('beforeunload', this.handleUnload);
    },

    methods: {
        handleUnload(event: Event) {
            if (this.hasUnsavedChanges) event.preventDefault();
        },

        async loadClientGroups() {
            if (!this.currentUser?.clientGroups || this.currentUser.clientGroups.length === 0) {
                this.clientGroupsWithLogos = [];
                return;
            }

            try {
                this.clientGroupsLoading = true;
                const clientGroupResponses = await Promise.all(this.currentUser.clientGroups.map(group => {
                    return this.$store.state.apiClient.get(`/client-groups/${group.id}`);
                }));
                const clientGroups = clientGroupResponses.map(({ data }) => data.clientGroup);
                clientGroups.sort((g1, g2) => g1.name.localeCompare(g2.name));
                this.clientGroupsWithLogos = clientGroups.filter(group => group.logo);
            } catch (error) {
                console.error(error);
                this.clientGroupsWithLogos = [];
            } finally {
                this.clientGroupsLoading = false;
            }
        },

        resetState() {
            this.state.avatar = this.currentUser?.avatar ?? null;
            this.state.firstName = this.currentUser?.firstName ?? '';
            this.state.lastName = this.currentUser?.lastName ?? '';
            this.state.mobilePhone = this.currentUser?.mobilePhone ?? '';
            this.state.smsPermitted = this.currentUser?.communicationPreferences.notifyMethod.includes('sms') ?? false;
            this.state.description = this.currentUser?.description ?? '';
            this.state.email = this.currentUser?.email ?? '';
            // this.state.location = null;
            this.state.lngLat = this.currentUser ? [this.currentUser.lng, this.currentUser.lat] : null;
            this.state.measurementSystem = this.currentUser?.userSettings?.preferredSystemOfMeasure;
        },

        async handlePhoneNumberInput() {
            const pattern = /[+-\d\s()]*/g;
            await this.$nextTick();
            this.state.mobilePhone = this.state.mobilePhone.match(pattern)?.join('') ?? '';
        },

        async handleSubmit(event: Event) {
            try {
                event.preventDefault();

                if (!this.currentUser) return;
                if (this.submissionInProgress) return;

                this.submissionInProgress = true;

                const notifyMethodSet = new Set(this.currentUser.communicationPreferences.notifyMethod);
                notifyMethodSet[this.state.smsPermitted ? 'add' : 'delete']('sms');

                const profileChanges = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    description: this.state.description,
                    mobilePhone: this.state.mobilePhone,
                    communicationPreferences: {
                        ...this.currentUser.communicationPreferences,
                        notifyMethod: Array.from(notifyMethodSet),
                    },
                };

                if (this.state.avatar !== this.currentUser.avatar) {
                    if (this.state.avatar instanceof File) {
                        const { error } = await this.$store.dispatch('updateAvatar', { file: this.state.avatar, successMessage: false });
                        if (error) throw Error;
                    } else {
                        Object.assign(profileChanges, { avatar: this.state.avatar });
                    }
                }

                if (this.state.measurementSystem !== this.currentUser.userSettings?.preferredSystemOfMeasure) {
                    const { error } = await this.$store.dispatch('updateSettings', {
                        preferredSystemOfMeasure: this.state.measurementSystem,
                    });
                    if (error) throw Error;
                }

                // Including lng/lat keys changes some other settings, so only do so if necessary.
                if (this.state.lngLat?.[0] !== this.currentUser.lng && this.state.lngLat?.[1] !== this.currentUser.lat) {
                    Object.assign(profileChanges, {
                        lng: this.state.lngLat?.[0],
                        lat: this.state.lngLat?.[1],
                        addressComponents: this.state.location && parseAddressComponentsFromMBobj(this.state.location),
                    });
                }

                const { error } = await this.$store.dispatch('updateUserDetails', profileChanges);
                if (error) throw Error;

                await this.$nextTick();
                trackProfileUpdate(this.currentUser);

                this.resetState();
            } catch (error) {
                // The store displays errors on its own. TODO: Let's not do that.
                console.error(error);
            } finally {
                this.submissionInProgress = false;
            }
        }
    },
});
</script>

<style scoped>

.field {
    display: block;
    margin-block: 1rem;
}

.label {
    font-size: calc(12rem / 16);
}

.avatar-wrapper {
    display: inline-block;
    margin-inline-end: 1ch;
    position: relative;
    vertical-align: middle;
}

.avatar {
    border-radius: 50%;
    box-shadow: 0 0 0 1px #8888 inset;
    height: 72px;
    vertical-align: middle;
    width: 72px;
}

.avatar-icon {
    background: white;
    color: black;
    border: 2px solid;
    border-radius: 50%;
    display: grid;
    font-size: 20px;
    height: 20px;
    inset: auto 0 0 auto;
    padding-bottom: 3px;
    place-content: center;
    position: absolute;
    width: 20px;
}

.client-group-logo-button {
    align-items: center;
    background: #D5EAFE;
    border-radius: 16px;
    display: flex;
    gap: 1ch;
    line-height: 1.2;
    margin-block: 4px;
    padding: 8px;
    text-align: start;
    width: 100%;
}

.client-group-logo-button > :last-child {
    flex: 1;
    text-align: end;
}

.client-group-logo-button .avatar {
    height: 48px;
    width: 48px;
}

.client-group-logo-button .action {
    color: var(--color-primary);
    font-size: small;
    white-space: nowrap;
}

.byline-preview {
    max-width: fit-content;
    margin: 16px auto;
}

.byline {
    background: #8881;
    border-radius: 16px;
    display: inline-block;
    font-weight: 500;
    margin-inline: 1ch;
    padding: 16px;
}

.byline .avatar {
    height: 2.5em;
    width: 2.5em;
}

.byline .name {
    color: var(--color-primary);
}

.private {
    background: #ECF6FF;
    border-radius: 16px;
    margin-inline: -24px;
    padding: 24px;
}

.footer {
    margin-block: 24px;
}

.delete-container {
    margin-block-start: 24px;
    text-align: center;
}
</style>
