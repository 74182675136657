<template>
    <v-container>
        <form class="mb-10" @submit.prevent="updateEmail">
            <validation-provider ref="emailValidationProvider" v-slot="{ errors }" name="email" rules="required|email">
                <base-input
                    v-model="email"
                    :label="$t('settings.login.emailLabel')"
                    type="email"
                    :loading="checkingEmailValidity"
                    :error-messages="emailInUse ? $t('settings.login.emailInUse') : errors"
                    :hint="currentUser && currentUser.sciStarterId ? $t('settings.login.affectsSciStarter') : ''"
                    :persistent-hint="currentUser && !!currentUser.sciStarterId"
                    @keyup="handleEmailInput"
                />
            </validation-provider>

            <action-footer class="text-center">
                <base-button type="submit" color="primary" :disabled="!emailIsSubmittable" :loading="savingEmail">
                    {{ $t('settings.login.updateEmail') }}
                </base-button>
            </action-footer>
        </form>

        <form @submit.prevent="updatePassword">
            <base-input v-model="password" :label="$t('settings.login.passwordLabel')" type="password" />
            <base-input v-model="password2" :label="$t('settings.login.passwordConfirmationLabel')" type="password" />
            <password-rules :password="password" />

            <action-footer class="text-center">
                <base-button type="submit" color="primary" :disabled="!passwordIsSubmittable" :loading="savingPassword">
                    {{ $t('settings.login.updatePassword') }}
                </base-button>
            </action-footer>
        </form>

        <template v-if="$vuetify.breakpoint.xs">
            <hr class="mt-12 mb-6">

            <i18n tag="p" path="settings.login.deleteAccount" class="text-center">
                <template #link>
                    <router-link :to="{ name: 'account.delete' }">{{ $t('settings.login.deleteAccountLink') }}</router-link>
                </template>
            </i18n>
        </template>
    </v-container>
</template>
<script lang="ts">
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import PasswordRules from '@/components/PasswordRules.vue';
import ActionFooter from '@/components/ActionFooter.vue';
import debounce from 'lodash/debounce';
import { trackProfileUpdate } from '@/tracking';

export default Vue.extend({
    name: 'LoginDetails',
    components: {
        ValidationProvider,
        PasswordRules,
        ActionFooter,
    },
    data() {
        return {
            email: '',
            checkingEmailValidity: false,
            emailInUse: false,
            savingEmail: false,
            password: '',
            password2: '',
            savingPassword: false,
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
        samePassword(): boolean {
            return this.password === this.password2 && this.password !== '';
        },
        hasNumber(): boolean {
            return !!this.password.match(/[0-9]/);
        },
        hasLength(): boolean {
            return this.password.length > 7;
        },
        hasCap(): boolean {
            return !!this.password.match(/[A-Z]/);
        },
        emailIsSubmittable(): boolean {
            return this.email !== this.currentUser?.email && (this.$refs.emailValidationProvider as any)?.errors.length === 0 && !this.emailInUse;
        },
        passwordIsSubmittable(): boolean {
            return  this.samePassword && this.hasNumber && this.hasLength && this.hasCap;
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(currentUser) {
                this.email = currentUser?.email ?? '';
            }
        }
    },
    methods: {
        async handleEmailInput() {
            this.emailInUse = false;
            const { valid } = await (this.$refs.emailValidationProvider as any).validateSilent();
            if (valid) {
                this.validateEmail(this.email);
            }
        },
        validateEmail: debounce(async function(this: any, emailAddress: string) {
            if (emailAddress.trim() !== this.currentUser?.email.trim()) {
                this.checkingEmailValidity = true;
                const { error } = await this.$store.dispatch('checkEmailValidity', emailAddress);
                this.checkingEmailValidity = false;
                if (error) {
                    this.emailInUse = true;
                }
            }
        }, 500),
        async updateEmail() {
            this.savingEmail = true;
            const { data } = await this.$store.dispatch('updateUserDetails', { email: this.email });
            trackProfileUpdate(data.users[0]);
            this.savingEmail = false;
        },
        async updatePassword() {
            if (!this.samePassword) {
                return;
            }
            this.savingPassword = true;
            const result = await this.$store.dispatch('accountUpdatePassword', this.password);
            this.savingPassword = false;
            if (result.error) {
                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: 'Whoops, we were unable to update your password.',
                });
                return;
            }
            this.$store.dispatch('alertUser', {
                type: 'info',
                message: 'Your password has been updated, you can use this new password next time you log in.',
            });
            this.password = '';
            this.password2 = '';
            return;
        },
    },
});
</script>
<style lang="postcss" scoped>
.password-rules {
    color: var(--color-dark-tint);
}
</style>
