<template>
    <section data-test-id="Location setup form">
        <h1 class="t-title text-center mb-8">{{ $t('account.setLocation') }}</h1>

        <p>{{ $t('account.whySetLocation') }}</p>

        <two-step-address-selector
            ref="addressSelector"
            :location.sync="location"
            :coordinates.sync="coordinates"
            data-test-id="Address selector"
        />

        <p v-if="submissionError" class="text-center error-message">{{ submissionError }}</p>

        <div class="text-center">
            <base-button
                type="submit"
                color="primary"
                prepend-icon="arrow_forward"
                :loading="saving"
                data-test-id="Submit button"
                @click="submit"
            >
                {{ $t('account.looksGood') }}
            </base-button>
        </div>
    </section>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import { parseAddressComponentsFromMBobj } from '@/util.mapbox';
import { MBAddressObj, LngLat } from '@/types';
import { trackRegistrationLocation } from '@/tracking';


export default Vue.extend({
    name: 'SetupLocation',

    components: {
        TwoStepAddressSelector,
    },

    data() {
        return {
            location: null as MBAddressObj | null,
            coordinates: null as LngLat | null,
            saving: false,
            submissionError: null as string | null,
        };
    },

    methods: {
        async submit() {
            this.submissionError = null;

            (this.$refs.addressSelector as any).validate();

            if (this.location && this.coordinates) {
                this.saving = true;

                // `communicationPreferences` are set on the back end when the address is set for the first time.
                // `notifyMethod` is set in the first registration step, so make sure it's preserved.
                const notifyMethod = this.$store.state.account.currentUser?.communicationPreferences.notifyMethod;

                const save = await this.$store.dispatch('updateUserDetails', {
                    addressComponents: parseAddressComponentsFromMBobj(this.location),
                    lng: this.coordinates[0],
                    lat: this.coordinates[1],
                    successMessage: false
                });

                const secondSave = await this.$store.dispatch('updateUserDetails', {
                    communicationPreferences: {
                        ...save.data.users[0].communicationPreferences,
                        notifyMethod,
                    },
                    successMessage: false
                });

                trackRegistrationLocation(secondSave.data.users[0]);

                this.saving = false;

                if (save.error) {
                    this.submissionError = this.$t('account.locationFailed') as string;
                }
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.error-message {
    color: var(--color-danger);
}
</style>
