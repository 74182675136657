<template>
    <base-page-content>
        <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="auto">
                <heading-level>
                    <heading aria-hidden="true">{{ '&thinsp;' }}</heading><!--For alignment-->
                </heading-level>

                <v-tabs vertical class="tabs">
                    <v-tab :to="{ name: 'account.profile' }">{{ $t('nav.profile') }}</v-tab>
                    <v-tab :to="{ name: 'account.notifications' }">{{ $t('nav.notifications') }}</v-tab>
                    <v-tab :to="{ name: 'account.sensors' }">{{ $t('nav.sensorsAndIntegrations') }}</v-tab>
                    <v-tab :to="{ name: 'account.delete' }">{{ $t('nav.deleteAccount') }}</v-tab>
                </v-tabs>
            </v-col>

            <v-col class="content">
                <router-view />
            </v-col>
        </v-row>
    </base-page-content>
</template>

<script lang="ts">
import BasePageContent from '@/components/BasePageContent.vue';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    metaInfo: {
        title: 'Account',
    },

    components: {
        BasePageContent,
        Heading,
        HeadingLevel,
    },
});
</script>

<style scoped>
.tabs {
    margin-inline-end: 2em;
}

.tabs :global(.v-tab) {
    justify-content: unset;
    letter-spacing: unset;
    text-transform: unset;
}

.content :deep(h2) {
    margin-bottom: 2em;
}
</style>
